import { InvestorReportsDetailsData } from '@/pages/InvestorReporting/utils/investorReports';
import { Button } from 'react-daisyui';
import {
  faEye,
  faLock,
  faLockOpen,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState, useEffect } from 'react';
import { Client, InvestorReportCardDTO } from '@types';
import { InvestorReportAnswer } from '@/pages/InvestorReporting/components/Clients/InvestorReportAnswer';
import { Spinner } from '@/components/Spinner';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { patch, post } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { ToastContainer } from 'react-toastify';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { InvestorReportCardButton } from '@/pages/InvestorReporting/components/InvestorReportCardButton';
import RequestBuilder from '@/pages/InvestorReporting/components/modals/RequestBuilder';
import { GenericModal } from '@/components/Modal';
import {
  ReportsFrequencies,
  ReportStatuses,
} from '../../../../../shared/constants';

const finalCardStatus = [ReportStatuses.closed, ReportStatuses.deleted];

export function InvestorReportsInfo({
  clientId,
  investorReportsData,
  refreshData,
}: {
  clientId: number;
  investorReportsData: InvestorReportsDetailsData;
  refreshData: () => Promise<void>;
}) {
  const { auth }: IUserContext = useUserContext();
  const [reportId, setReportId] = useState<number | undefined>(
    investorReportsData.selected_investor_report?.id
  );
  const [cardToAnswer, setCardToAnswer] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [cardToClose, setCardToClose] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [cardToReopen, setCardToReopen] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [sendNotification, setSendNotification] = useState<boolean>(false);
  const [isDataRefreshed, setIsDataRefreshed] = useState(false);
  const frequencies = [
    { index: 0, label: ReportsFrequencies.monthly },
    { index: 1, label: ReportsFrequencies.quarterly },
    { index: 2, label: ReportsFrequencies.annual },
  ];

  const enabledFrequencies = useMemo(
    () => investorReportsData.investor_reports.map((rep) => rep.frequency),
    [investorReportsData.investor_reports]
  );
  const isClient = auth?.isClient;
  const isInvestor = auth?.isInvestor;

  const report = useMemo(() => {
    if (reportId) {
      return investorReportsData.investor_reports.find(
        (rep) => rep.id === reportId
      );
    }
    return undefined;
  }, [investorReportsData.investor_reports, reportId]);

  const onChangeFrequency = (frequency: string) => {
    setCardToAnswer(undefined);
    setReportId(
      investorReportsData.investor_reports.find(
        (rep) => rep.frequency === frequency
      )?.id || undefined
    );
  };

  const isReportComplete = useMemo(() => {
    if (report) {
      const isReportNotClosed = report.status !== ReportStatuses.closed;
      const allCardAnswered =
        report.investor_report_cards?.every(
          (card) =>
            card.status === ReportStatuses.completed ||
            card.status === ReportStatuses.closed
        ) || false;
      return isReportNotClosed && allCardAnswered;
    }
    return false;
  }, [report]);

  useEffect(() => {
    if (isDataRefreshed && isReportComplete) {
      setSendNotification(true);
      setIsDataRefreshed(false);
    }
  }, [isDataRefreshed, isReportComplete]);

  const handleCardChangeStatus = async (
    card: InvestorReportCardDTO,
    status: ReportStatuses.closed | ReportStatuses.new
  ) => {
    setIsSaving(true);
    try {
      await patch(
        `investorReports/investorReportsCard/client/${clientId}/status/${
          card?.id as number
        }`,
        {
          status,
        }
      );
      alertMessageSuccess('Card updated successfully');
      setCardToClose(undefined);
      setCardToReopen(undefined);
      await refreshData();
      setIsDataRefreshed(true);
    } catch {
      alertErrorMessage('Failed to update card, please try again later');
    }
    setIsSaving(false);
  };

  const onHandleMarkAsClosed = async () => {
    await handleCardChangeStatus(
      cardToClose as InvestorReportCardDTO,
      ReportStatuses.closed
    );
  };

  const onHandleReopenCard = async () => {
    await handleCardChangeStatus(
      cardToReopen as InvestorReportCardDTO,
      ReportStatuses.new
    );
  };

  const onHandleCardUpdate = async () => {
    await refreshData();
    setCardToAnswer(undefined);
    setIsDataRefreshed(true);
  };

  const getStatusCSS = (status: ReportStatuses) => {
    switch (status) {
      case ReportStatuses.new:
        return 'bg-gray-300';
      case ReportStatuses.partial:
        return 'bg-gray-300';
      case ReportStatuses.completed:
        return 'badge-primary';
      case ReportStatuses.closed:
        return 'badge-warning';
      case ReportStatuses.overdue:
        return 'badge-error';
      case ReportStatuses.deleted:
        return 'badge-error';
      default:
        return 'bg-gray-300';
    }
  };

  const selectCard = (card: InvestorReportCardDTO) => {
    setCardToAnswer(undefined);
    setTimeout(() => {
      setCardToAnswer(card);
    }, 50);
  };

  const onHandleSendUpdates = async () => {
    await post(`investorReports/notify/${report?.id as number}`, {});
    await refreshData();
    setCardToAnswer(undefined);
    setSendNotification(false);
    alertMessageSuccess('Notification sent successfully');
  };

  if (!report) {
    return <span>No report</span>;
  }

  return (
    <div className='flex'>
      <ToastContainer />
      {cardToClose ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleMarkAsClosed}
          onCloseAction={() => setCardToClose(undefined)}
          showModal={!!cardToClose}
          modalMessage='Are you sure you want to mark this card as closed?'
        />
      ) : null}
      {cardToReopen ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleReopenCard}
          onCloseAction={() => setCardToReopen(undefined)}
          showModal={!!cardToReopen}
          modalMessage='Are you sure you want to reopen this card?'
        />
      ) : null}
      {sendNotification ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleSendUpdates}
          onCloseAction={() => setSendNotification(false)}
          showModal={sendNotification}
          modalMessage='Are you sure you want to notify the investor?'
        />
      ) : null}
      <div
        className={`${isSaving ? 'spinner-container' : 'hidden'}`}
        id='spinner-total'
        data-testid='spinner-transaction'
      >
        <Spinner className='spinner' />
      </div>
      <div className='w-[50%]'>
        <div className='p-4'>
          {isInvestor ? 'Client Name:' : 'Investor Name:'}
          <span className='ml-2 font-bold'>
            {isInvestor
              ? investorReportsData.investor_reports[0].client?.client_name
              : investorReportsData.investor_name}
          </span>
        </div>
        <div className='pl-4 flex'>
          {frequencies.map((data) => {
            return (
              <span key={`${data.index + 1}_frequency_span`}>
                <Button
                  size='sm'
                  onClick={() => onChangeFrequency(data.label)}
                  className={`${
                    data.label === report.frequency ? 'btn-active' : ''
                  }`}
                  key={`${data.index + 1}_frequency_button`}
                  disabled={!enabledFrequencies.includes(data.label)}
                >
                  {data.label}
                </Button>
              </span>
            );
          })}
        </div>

        <div className='flex'>
          <div className='p-4'>
            Period:
            <span className='ml-2 font-bold'>{report.period}</span>
          </div>
          <div>
            {isInvestor ? (
              <Button size='xs' className='btn-accent mt-4' onClick={() => {}}>
                Edit Template
              </Button>
            ) : null}
          </div>
        </div>

        <div className='flex flex-wrap w-full'>
          {report.investor_report_cards?.map((card) => (
            <div
              key={`card-${card.title}`}
              className={`items-center my-2 mx-2 shadow card rounded-lg 
                text-gray-900 w-[45%] justify-center ${getStatusCSS(
                  card.status
                )}`}
            >
              <div key={`card-${card.title}`} className='my-2 mx-2'>
                <div className='mt-2 mx-2 text-center'>
                  <span className='font-bold'>Title: {card.title}</span>
                  <div>
                    <span className='font-bold text-sm'>
                      Status: {card.status}
                    </span>
                  </div>
                  <div>
                    <span>Description: {card.description}</span>
                  </div>
                  <div>
                    {card.status === ReportStatuses.new &&
                    !finalCardStatus.includes(report.status) &&
                    isClient ? (
                      <div className='flex'>
                        <InvestorReportCardButton
                          label='Mark as Closed'
                          icon={faLock}
                          color='error'
                          onClick={() => {
                            setCardToAnswer(undefined);
                            setCardToClose(card);
                          }}
                          data-testid={`mark-closed-${card.id as number}`}
                        />
                        <InvestorReportCardButton
                          label='Answer request'
                          icon={faPencil}
                          color='primary'
                          onClick={() => {
                            selectCard(card);
                          }}
                          data-testid={`answer-card-${card.id as number}`}
                        />
                      </div>
                    ) : null}
                    {card.status === ReportStatuses.closed &&
                    !finalCardStatus.includes(report.status) &&
                    isClient ? (
                      <InvestorReportCardButton
                        label='Reopen Card'
                        icon={faLockOpen}
                        color='error'
                        onClick={() => {
                          setCardToAnswer(undefined);
                          setCardToReopen(card);
                        }}
                        data-testid={`reopen-${card.id as number}`}
                      />
                    ) : null}
                    {card.status === ReportStatuses.completed &&
                    (isClient || report.status === ReportStatuses.closed) ? (
                      <InvestorReportCardButton
                        label='View Response'
                        icon={faEye}
                        color='accent'
                        onClick={() => selectCard(card)}
                        data-testid={`view-response-${card.id as number}`}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='flex flex-wrap w-full ml-4 justify-center'>
          <div className='w-[45%]'>
            <Button
              type='submit'
              title='login'
              color='accent'
              size='sm'
              className='btn btn-accent bg-transparent my-2 mx-4'
              data-testid='send-update-button'
              disabled={!isReportComplete}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => setSendNotification(true)}
            >
              Click here to send updates
            </Button>
          </div>
        </div>
      </div>
      {cardToAnswer && isClient ? (
        <InvestorReportAnswer
          investorReportsCard={cardToAnswer}
          client={report.client as Client}
          setSpinner={(value) => {
            setIsSaving(value);
          }}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onCardUpdated={() => onHandleCardUpdate()}
        />
      ) : null}
      {cardToAnswer && isInvestor ? (
        <GenericModal isOpen onClose={() => {}}>
          <InvestorReportAnswer
            investorReportsCard={cardToAnswer}
            client={report.client as Client}
            setSpinner={(value) => {
              setIsSaving(value);
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCardUpdated={() => onHandleCardUpdate()}
            isFullWidth
          />
        </GenericModal>
      ) : null}
      {isInvestor ? (
        <div className='w-[50%]'>
          <RequestBuilder
            clientId={investorReportsData.selected_investor_report?.client_id}
            onSave={async () => refreshData()}
          />
        </div>
      ) : null}
    </div>
  );
}
